import { ElectricityBar } from '@/components/atoms/ElectricityBar/ElectrictyBar';
import { Input } from '@/components/atoms/Input/Input';
import { OilBar } from '@/components/atoms/OilBar/OilBar';
import RangeSlider, { SizeVariants } from '@/components/atoms/RangeSlider';
import {
  Switcher,
  SwitcherOptionType,
  SwitcherVariants,
} from '@/components/atoms/Switcher/Switcher';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import useWindowSize from '@/hooks/useWindowSize';
import {
  DEFAULT_COSTS,
  GAS_SAVINGS_INITIAL_TERM,
  STYLE_TO_EFFICIENCY,
} from '@/lib/constants';
import { EfficiencyRates } from '@/lib/schema/inventory/types';
import { GasVsElectricCosts } from '@/types/fuel-type';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import Accordion, { AccordionProps } from '../Accordion';

export const enum CarTypes {
  'Truck' = 'Truck',
  'SUV' = 'SUV',
  'Sedan' = 'Sedan',
}

const CAR_TYPE_OPTIONS: SwitcherOptionType[] = [
  {
    text: CarTypes.Truck,
    subText: '18 MPG',
    value: CarTypes.Truck,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Truck} width={'59px'} height={'26px'} />,
    },
  },
  {
    text: CarTypes.SUV,
    subText: '20 MPG',
    value: CarTypes.SUV,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Suv} width={'59px'} height={'26px'} />,
    },
  },
  {
    text: CarTypes.Sedan,
    subText: '29 MPG',
    value: CarTypes.Sedan,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Sedan} width={'59px'} height={'26px'} />,
    },
  },
];

const TimePeriodOptions = [
  {
    text: '1',
    value: '1',
  },
  {
    text: '2',
    value: '2',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '4',
    value: '4',
  },
  {
    text: '5',
    value: '5',
  },
];

export const getCostPerYear = (
  mileage: string | number,
  cost: string | number,
  efficiency: string | number
) => {
  return Math.round((Number(mileage) * Number(cost)) / Number(efficiency));
};

const EfficiencyInput = ({
  gasolineCost,
  handleChangeGasPerGallon,
  electricityCost,
  handleElectricityCostChange,
}: {
  gasolineCost: string;
  handleChangeGasPerGallon: (value: string) => void;
  electricityCost: string;
  handleElectricityCostChange: (value: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-s pb-s">
      <Input
        aria-label="Gasoline cost per gallon"
        type="number"
        value={gasolineCost}
        onChange={handleChangeGasPerGallon}
        label="Gasoline cost per gallon"
        append="/gallon"
        prepend="$"
        id={'gasolineCost'}
      />

      <Input
        aria-label="Electricity cost per kWh"
        type="number"
        value={electricityCost}
        onChange={handleElectricityCostChange}
        label="Electricity cost"
        append="/kWh"
        prepend="$"
        id={'electricityCost'}
      />
    </div>
  );
};

interface GVECalculatorProps
  extends Pick<AccordionProps, 'expanded' | 'setExpanded'>,
    GasVsElectricCosts {
  initialCarType?: CarTypes;
  variant?: 'vdp' | 'homepage' | 'report';
  efficiencyRates?: EfficiencyRates;
  onChangeFuelPeriod?: (value: string) => void;
  onChangeAnnualMiles?: (value: string) => void;
  onChangeGasPerGallon?: (value: string) => void;
  onChangeElectricityCost?: (value: string) => void;
  setAllowTouchMove?: (value: boolean) => void;
}

export const GVECalculator = ({
  initialCarType,
  variant = 'vdp',
  expanded = false,
  setExpanded,
  efficiencyRates,
  mileagePerYear: initialMileagePerYear,
  gasolineCost: initialGasolineCost,
  electricityCost: initialElectricityCost,
  onChangeFuelPeriod,
  onChangeAnnualMiles,
  onChangeGasPerGallon,
  onChangeElectricityCost,
  setAllowTouchMove,
}: GVECalculatorProps) => {
  const { isMobile } = useWindowSize();

  const [years, setYears] = useState(GAS_SAVINGS_INITIAL_TERM);
  const [mileagePerYear, setMileagePerYear] = useState(
    initialMileagePerYear || DEFAULT_COSTS.mileagePerYear
  );
  const [gasolineCost, setGasolineCost] = useState(
    initialGasolineCost || DEFAULT_COSTS.gasolineCost
  );
  const [electricityCost, setElectricityCost] = useState(
    initialElectricityCost || DEFAULT_COSTS.electricityCost
  );
  const [carType, setCarType] = useState(initialCarType || CarTypes.Sedan);

  const { totalSavings, evWidth, gasCost, evCost } = useMemo(() => {
    const gasC = Math.round(
      getCostPerYear(
        mileagePerYear,
        gasolineCost,
        efficiencyRates
          ? efficiencyRates.gas_rate
          : STYLE_TO_EFFICIENCY[carType].gas
      )
    );

    const electricRate =
      100 /
      (efficiencyRates
        ? efficiencyRates.ev_rate
        : Number(STYLE_TO_EFFICIENCY[carType].electric));
    const evC = getCostPerYear(mileagePerYear, electricityCost, electricRate);

    const calculatedTotalSavings = (gasC - evC) * Number(years);
    const calculatedEvWidth = String((evC / gasC) * 100);
    return {
      totalSavings: calculatedTotalSavings,
      evWidth: calculatedEvWidth,
      gasCost: gasC,
      evCost: evC,
    };
  }, [
    efficiencyRates,
    carType,
    electricityCost,
    gasolineCost,
    mileagePerYear,
    years,
  ]);

  const handleChangeYears = useCallback(
    (newYearValue: string) => {
      if (onChangeFuelPeriod) {
        onChangeFuelPeriod(newYearValue);
      }
      setYears(newYearValue);
    },
    [onChangeFuelPeriod]
  );

  const handleChangeMileagePerYear = useCallback(
    (value: string) => {
      if (onChangeAnnualMiles) {
        onChangeAnnualMiles(value);
      }
      setMileagePerYear(value);
    },
    [onChangeAnnualMiles]
  );

  const handleChangeGasPerGallon = useCallback(
    (value: string) => {
      if (onChangeGasPerGallon) {
        onChangeGasPerGallon(value);
      }
      setGasolineCost(value);
    },
    [onChangeGasPerGallon]
  );

  const handleElectricityCostChange = useCallback(
    (value: string) => {
      if (onChangeElectricityCost) {
        onChangeElectricityCost(value);
      }
      setElectricityCost(value);
    },
    [onChangeElectricityCost]
  );

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col overflow-hidden rounded-small border border-neutral-200',
        {
          'shadow-xl l:flex-row': variant === 'homepage',
          'ml:flex-row': variant === 'vdp',
          'bg-neutral-0': variant === 'report',
        }
      )}
    >
      {/* Form Input */}
      <section
        className={clsx('flex w-full flex-col gap-l bg-neutral-0 p-xl', {
          'ml:w-1/2 l:w-1/3': variant === 'vdp',
          'order-2': variant === 'report',
        })}
      >
        {variant !== 'report' && (
          <h3 className="text-h3SemiBold text-neutral-900">Gas savings</h3>
        )}
        <div className="flex flex-col gap-l">
          {variant !== 'report' && (
            <div className="flex flex-col gap-s">
              <div className="text-body2Regular text-neutral-800">
                Time period in years
              </div>
              <Switcher
                variant={SwitcherVariants.Number}
                options={TimePeriodOptions}
                value={years}
                onChange={handleChangeYears}
                aria-label={'Gas savings term in years'}
              />
            </div>
          )}

          <div className="flex w-full flex-col gap-s">
            <div className="text-body2Regular text-neutral-800">
              Miles driven per year
            </div>
            <div className="flex w-full gap-s">
              <RangeSlider
                aria-label="Annual miles"
                min={5000}
                max={30000}
                step={100}
                defaultValue={[Number(mileagePerYear)]}
                size={isMobile ? SizeVariants.Mobile : SizeVariants.Desktop}
                onValueChange={(value: number[]) => {
                  handleChangeMileagePerYear(value[0].toString());
                }}
                values={[Number(mileagePerYear)]}
                setAllowTouchMove={setAllowTouchMove}
              />
              <div className="flex text-body2Regular text-neutral-800">
                {Number(mileagePerYear).toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-s">
          {variant === 'report' ? (
            <EfficiencyInput
              gasolineCost={gasolineCost}
              handleChangeGasPerGallon={handleChangeGasPerGallon}
              electricityCost={electricityCost}
              handleElectricityCostChange={handleElectricityCostChange}
            />
          ) : (
            <Accordion
              title={'Edit vehicle efficiency'}
              icon={{ id: 'chevron' }}
              buttonClassName="text-blue-medium hover:text-blue-dark hover:underline"
              expanded={expanded}
              setExpanded={setExpanded}
            >
              <EfficiencyInput
                gasolineCost={gasolineCost}
                handleChangeGasPerGallon={handleChangeGasPerGallon}
                electricityCost={electricityCost}
                handleElectricityCostChange={handleElectricityCostChange}
              />
            </Accordion>
          )}
        </div>

        {variant === 'homepage' && (
          <div className="pt-s m:pt-0">
            <Switcher
              aria-label="Car type"
              options={CAR_TYPE_OPTIONS}
              value={carType}
              variant={SwitcherVariants.Text}
              onChange={(e) => {
                setCarType(e as CarTypes);
              }}
            />
          </div>
        )}
      </section>

      {/* Graph Output */}
      <section
        className={clsx('flex w-full flex-col gap-l px-xl', {
          'bg-neutralsGrey-200 py-xl': variant === 'homepage',
          'bg-neutralsGrey-200 py-xl ml:w-1/2 l:w-2/3': variant === 'vdp',
          'order-1 bg-neutral-0 pt-xl': variant === 'report',
        })}
      >
        {variant === 'report' && (
          <h3 className="text-h3SemiBold text-neutral-900">Cost comparison</h3>
        )}

        <span className="whitespace-nowrap text-body1Light text-neutral-900">
          Est. Savings over {years} year{years === '1' ? '' : 's'}:{' '}
          <span className="text-h4Regular">
            ${totalSavings.toLocaleString()}
          </span>
        </span>

        <ElectricityBar
          evWidth={Number(evWidth)}
          evCost={evCost}
          white={variant === 'report'}
        />

        <OilBar gasCost={gasCost} white={variant === 'report'} />

        {variant === 'vdp' && (
          <section className="flex flex-grow items-end">
            <ToolTip
              aria-label="Efficiency rates and calculations disclosure tooltip"
              hoverElement={
                <div className="text-microMedium underline">
                  View calculations
                </div>
              }
            >
              {efficiencyRates && (
                <div>
                  <p>
                    This comparison evaluates average EV{' '}
                    {efficiencyRates.vehicle_style}s and gasoline{' '}
                    {efficiencyRates.vehicle_style}s based on class and
                    features. Efficiency assessments are guided by EPA
                    standards, factoring in 45% highway and 55% city driving for{' '}
                    {Intl.NumberFormat('en-US').format(Number(mileagePerYear))}{' '}
                    annual miles. Costs are based on national averages: $
                    {Number(gasolineCost).toFixed(2)} per gallon of gasoline and
                    ${Number(electricityCost).toFixed(2)} per kWh for
                    electricity. Actual savings may vary due to market changes
                    and driving conditions.
                  </p>
                  <p className="pt-s">
                    Average EV {efficiencyRates.vehicle_style} consumption
                    rating = {efficiencyRates.ev_rate} kWh/100mi
                  </p>
                  <p>
                    Average Gasoline {efficiencyRates.vehicle_style} consumption
                    rating = {efficiencyRates.gas_rate} MPG.
                  </p>
                </div>
              )}
              {!efficiencyRates && (
                <div>
                  <p>
                    {carType === CarTypes.Truck &&
                      'This comparison features the 2023 Ford F-150 Lightning 4WD and the 2023 Ford F150 Pickup 4WD, selected for their class and features. Efficiency evaluations follow EPA standards, considering 45% highway and 55% city driving over 10,000 annual miles. Costs are based on national averages: $3.60 per gallon of gasoline and $0.16 per kWh for electricity. Actual savings for trucks may vary due to market changes and driving conditions.'}
                    {carType === CarTypes.SUV &&
                      'This comparison evaluates average EV SUVs and gasoline SUVs based on class and features. Efficiency assessments are made according to EPA standards, with 45% highway and 55% city driving over 10,000 annual miles. Costs reflect national averages: $3.60 per gallon for gasoline and $0.16 per kWh for electricity. Actual savings for SUVs may vary due to market fluctuations and driving conditions.'}
                    {carType === CarTypes.Sedan &&
                      'This comparison uses the 2023 Model 3 Base AWD and 2023 Toyota Camry LE/SE, selected based on class and features. Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for 10,000 annual miles. Costs are based on national averages: $3.60 per gallon of gasoline and $0.16 per kWh for electricity. Actual savings may vary due to market changes and driving conditions.'}
                  </p>
                  {carType === CarTypes.Truck && (
                    <>
                      <p className="pt-s">
                        2023 Ford F-150 Lightning 4WD consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.Truck.electric} kWh/100mi.
                      </p>
                      <p>
                        2023 Ford F150 Pickup 4WD consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.Truck.gas} MPG.
                      </p>
                    </>
                  )}
                  {carType === CarTypes.SUV && (
                    <>
                      <p className="pt-s">
                        Average EV SUV consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.SUV.electric} kWh/100mi
                      </p>
                      <p>
                        Average Gasoline SUV consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.SUV.gas} MPG.
                      </p>
                    </>
                  )}
                  {carType === CarTypes.Sedan && (
                    <>
                      <p className="pt-s">
                        2023 Model 3, Base AWD consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.Sedan.electric} kWh/100mi.
                      </p>
                      <p>
                        2023 Toyota Camry LE/SE consumption rating ={' '}
                        {STYLE_TO_EFFICIENCY.Sedan.gas} MPG
                      </p>
                    </>
                  )}
                </div>
              )}
            </ToolTip>
          </section>
        )}
        {variant === 'report' && (
          <div className="border-b border-neutral-200" />
        )}
      </section>
    </div>
  );
};
